<script>
import api, { ApiDefaults } from '@/utils/api_call';
import { mapGetters } from 'vuex';
import { UPDATE_ARTICLE } from '../../utils/store/actions/general';
import { toRaw } from 'vue';

export default {
    name: 'Reactions',
    props: {
        article: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            default: function () {
                return {
                    pid: 2,
                    target: 'news'
                };
            }
        }
    },
    data() {
        return {
            open: false

        };
    },
    methods: {

        openReactions: function () {
            this.open = !this.open;
        },
        addReaction: function ( reaction ) {

            if ( typeof reaction === 'object' ) {
                reaction = reaction.target.dataset.react;
            }
            if ( typeof reaction === 'string' ) {
                let postData = new URLSearchParams();
                postData.append('tx_c3json_jsondatanews[controller]', 'JsonDataNews');
                postData.append('tx_c3json_jsondatanews[action]', 'toggleReaction');
                postData.append('tx_c3json_jsondatanews[news]', this.article.uid);
                postData.append('tx_c3json_jsondatanews[user]', this.getProfile.uid);
                postData.append('tx_c3json_jsondatanews[reaction]', reaction);

                let apiParams = Object.assign({}, ApiDefaults, {
                    params: {id: this.settings.pid},
                    method: 'post',
                    data: postData
                });
                api(apiParams)
                    .then(( response ) => {
                        console.log( {article: this.article, newData: {reactions: response.data.reactions}, target: this.settings.target} );
                        this.$store.commit(UPDATE_ARTICLE, {article: this.article, newData: {reactions: response.data.reactions}, target: this.settings.target});
                    })
                    .catch(error => console.log('Error', error));
            }
            this.open = false;
        }

    },
    computed: {
        ...mapGetters([ 'getProfile' ]),
        reactionClass: function () {
            let reacts = toRaw(this.article.reactions);

            return {
                'thumbs-up': {
                    'is-active': (Object.prototype.hasOwnProperty.call(reacts, 'thumbs-up' ) ? (reacts[ 'thumbs-up' ].indexOf(parseInt(this.getProfile.uid))  != -1) : false)
                },
                heart: {
                    'is-active': (Object.prototype.hasOwnProperty.call(reacts, 'heart' ) ? (reacts.heart.indexOf(parseInt(this.getProfile.uid)) != -1) : false)
                },
                what: {
                    'is-active': (Object.prototype.hasOwnProperty.call(reacts, 'what' ) ? (reacts.what.indexOf(parseInt(this.getProfile.uid)) != -1) : false)
                },
                smile: {
                    'is-active': (Object.prototype.hasOwnProperty.call(reacts, 'smile' ) ? (reacts.smile.indexOf(parseInt(this.getProfile.uid)) != -1) : false)
                },
                celebrate: {
                    'is-active': (Object.prototype.hasOwnProperty.call(reacts, 'celebrate' ) ? (reacts.celebrate.indexOf(parseInt(this.getProfile.uid)) != -1) : false)
                }
            };
        }
    }
};
</script>

<template>
    <div class="reactions">
        <span class="reaction-add" @click.stop="openReactions"><inline-svg :src="require('@/assets/images/social/add-reaction.svg')" alt="add" width="24" /></span>
        <div class="reactions-list">
            <template v-for="(users,react) in article.reactions" :key="users.length">
                <span :class="reactionClass[react]" v-if="users.length !== 0" @click.stop="addReaction(react)" :data-react="react">
                    <inline-svg :src="require('@/assets/images/social/'+react+'.svg')" alt="Reaktionen" width="16" />
                    <span class="cnt">{{ users.length }}</span>
                </span>
            </template>
        </div>
        <div class="reactions-bar" v-show="open">
            <span @click.stop="addReaction('thumbs-up')"><inline-svg :src="require('@/assets/images/social/thumbs-up.svg')" alt="Reaktionen" width="24" /></span>
            <span @click.stop="addReaction('heart')"><inline-svg :src="require('@/assets/images/social/heart.svg')" alt="Reaktionen" width="24" /></span>
            <span @click.stop="addReaction('smile')"><inline-svg :src="require('@/assets/images/social/smile.svg')" alt="Reaktionen" width="24" /></span>
            <span @click.stop="addReaction('what')"><inline-svg :src="require('@/assets/images/social/what.svg')" alt="Reaktionen" width="24" /></span>
            <span @click.stop="addReaction('celebrate')"><inline-svg :src="require('@/assets/images/social/celebrate.svg')" alt="Reaktionen" width="24" /></span>
        </div>
    </div>
</template>

<style scoped lang="scss">

.reactions {
    position: relative;
    display: flex;
    column-gap: 4px;
}

.reaction-add {
    display: inline-block;
    border-radius: 12px;
    border: 1px solid rgba(240, 241, 243, 1);
    opacity: 1;
    background-color: rgba(240, 241, 243, 1);
    padding: 3px 8px;
    cursor: pointer;

    svg {
        height: 16px;
        width: auto;
        vertical-align: middle;
        pointer-events: none;
    }

    &:hover {
        border-color: var(--color-grey-60);
    }

    &:active {
        border-color: var(--color-primary);
        background-color: rgba(0, 79, 149, 0.1);
    }
}

.reactions-bar {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: auto;
    margin-bottom: 4px;
    border-radius: 16px;
    border: 1px solid rgba(204, 204, 204, 1);
    background-color: rgba(240, 241, 243, 1);
    padding: 4px 12px;
    display: flex;
    align-items: center;
    column-gap: 8px;

    span {
        cursor: pointer;
    }

    svg {
        max-width: unset;
    }
}

.reactions-list {
    display: flex;
    column-gap: 4px;

    & > span {
        position: relative;
        display: inline-flex;
        align-items: center;
        column-gap: 4px;
        padding: 4px 8px;
        border-radius: 12px;
        opacity: 1;
        border: 1px solid rgba(240, 241, 243, 1);
        background-color: rgba(240, 241, 243, 1);
        cursor: pointer;



        span.cnt {
            color: rgba(51, 51, 51, 1);
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            pointer-events: none;
        }

        svg {
            pointer-events: none;
            height: 16px;

            vertical-align: middle;
            max-width: unset;
            cursor: pointer;
        }

        &:hover {
            border-color: var(--color-grey-60);
        }

        &.is-active,
        &:active {
            border-color: var(--color-primary);
            background-color: rgba(0, 79, 149, 0.1);

            .cnt {
                color: var(--color-primary);
            }
        }
    }
}

</style>